<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column" v-html="title"></h3>
      <div class="d-flex domains-table-filters">
        <div>
          <button
            v-if="selectedDomains.length"
            class="btn btn-light bulk-settings-btn"
            @click="onOpenMultipleEdit()"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg
                :transformSource="transform"
                src="public-media/svg/icons/General/Settings-1.svg"
              />
            </span>
            Bulk setting
          </button>
        </div>
        <slot></slot>
      </div>
    </div>
    <div class="card-body pt-3 pb-0">
      <div
        v-if="!count"
        class="card-label font-weight-bolder text-dark text-center mb-3"
      >
        No data
      </div>
      <!--begin::Table-->
      <div v-if="count" class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="min-width: 20px"></th>
              <th class="p-0" style="min-width: 200px"></th>
              <th
                :class="`p-0 cell--sort ${
                  sorted_fields.total_visits && 'kt-datatable__cell--sorted'
                }`"
              >
                <span
                  class="d-flex align-items-center"
                  @click.self="onSorted('total_visits')"
                  >Total visits
                  <span
                    v-if="sorted_fields.total_visits"
                    :class="'order_sorted'"
                    >{{ sorted_fields.total_visits.ordering }}</span
                  >
                  <span
                    class="svg-icon svg-icon-md svg-icon-danger clear_sorted"
                    v-if="sorted_fields.total_visits"
                    title="Remove from sorting"
                    @click="clearSorted('total_visits')"
                  >
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Close.svg"
                    ></inline-svg>
                  </span>
                  <span
                    :class="`svg-icon svg-icon-md svg-icon-primary sorted_icon ${
                      sorted_fields.total_visits ? '' : 'd-none'
                    }`"
                  >
                    <inline-svg
                      :transformSource="transform"
                      :src="`public-media/svg/icons/Navigation/${buildSortedIconClass(
                        'total_visits'
                      )}.svg`"
                    ></inline-svg>
                  </span>
                </span>
              </th>
              <th
                :class="`p-0 cell--sort ${
                  sorted_fields.today_visits && 'kt-datatable__cell--sorted'
                }`"
              >
                <span
                  class="d-flex align-items-center"
                  @click.self="onSorted('today_visits')"
                  >Today visits
                  <span
                    v-if="sorted_fields.today_visits"
                    :class="'order_sorted'"
                    >{{ sorted_fields.today_visits.ordering }}</span
                  >
                  <span
                    class="svg-icon svg-icon-md svg-icon-danger clear_sorted"
                    v-if="sorted_fields.today_visits"
                    title="Remove from sorting"
                    @click="clearSorted('today_visits')"
                  >
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Close.svg"
                    ></inline-svg>
                  </span>
                  <span
                    :class="`svg-icon svg-icon-md svg-icon-primary sorted_icon ${
                      sorted_fields.today_visits ? '' : 'd-none'
                    }`"
                  >
                    <inline-svg
                      :transformSource="transform"
                      :src="`public-media/svg/icons/Navigation/${buildSortedIconClass(
                        'today_visits'
                      )}.svg`"
                    ></inline-svg>
                  </span>
                </span>
              </th>
              <th class="p-0 cell--sort">
                <span
                  class="d-flex align-items-center"
                  @click.self="onSorted('created_at')"
                  >Created at
                  <span
                    v-if="sorted_fields.created_at"
                    :class="'order_sorted'"
                    >{{ sorted_fields.created_at.ordering }}</span
                  >
                  <span
                    class="svg-icon svg-icon-md svg-icon-danger clear_sorted"
                    v-if="sorted_fields.created_at"
                    title="Remove from sorting"
                    @click="clearSorted('created_at')"
                  >
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Close.svg"
                    ></inline-svg>
                  </span>
                  <span
                    :class="`svg-icon svg-icon-md svg-icon-primary sorted_icon ${
                      sorted_fields.created_at ? '' : 'd-none'
                    }`"
                  >
                    <inline-svg
                      :transformSource="transform"
                      :src="`public-media/svg/icons/Navigation/${buildSortedIconClass(
                        'created_at'
                      )}.svg`"
                    ></inline-svg>
                  </span>
                </span>
              </th>
              <th class="p-0 cell--sort">
                <span
                  class="d-flex align-items-center"
                  @click.self="onSorted('last_visit_at')"
                  >Last visit at
                  <span
                    v-if="sorted_fields.last_visit_at"
                    :class="'order_sorted'"
                    >{{ sorted_fields.last_visit_at.ordering }}</span
                  >
                  <span
                    class="svg-icon svg-icon-md svg-icon-danger clear_sorted"
                    v-if="sorted_fields.last_visit_at"
                    title="Remove from sorting"
                    @click="clearSorted('last_visit_at')"
                  >
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Close.svg"
                    ></inline-svg>
                  </span>
                  <span
                    :class="`svg-icon svg-icon-md svg-icon-primary sorted_icon ${
                      sorted_fields.last_visit_at ? '' : 'd-none'
                    }`"
                  >
                    <inline-svg
                      :transformSource="transform"
                      :src="`public-media/svg/icons/Navigation/${buildSortedIconClass(
                        'last_visit_at'
                      )}.svg`"
                    ></inline-svg>
                  </span>
                </span>
              </th>
              <th class="p-0" style="min-width: 100px">IP</th>
              <th class="p-0" style="min-width: 100px">Type</th>
              <th class="p-0" style="min-width: 100px">Country</th>
              <th class="p-0" style="min-width: 50px">Enable</th>
              <th class="p-0" style="min-width: 80px"></th>
            </tr>
          </thead>
          <tbody v-if="list">
            <template v-for="(item, i) in list">
              <tr
                v-bind:key="i"
                :class="{ 'has-wrong-selector': item.has_wrong_selector }"
              >
                <td class="pl-0">
                  <div>
                    <label class="checkbox pl-0">
                      <input
                        type="checkbox"
                        :value="item.id"
                        v-model="selectedDomains" />
                      <span class="position-relative"></span
                    ></label>
                  </div>
                </td>
                <td class="pl-0">
                  <span
                    class="svg-icon svg-icon-md svg-icon-success"
                    v-if="item.is_whitelisted"
                  >
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Check.svg"
                    ></inline-svg>
                  </span>
                  <span class="svg-icon svg-icon-md svg-icon-danger" v-else>
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Close.svg"
                    ></inline-svg>
                  </span>
                  <router-link
                    :to="{ name: 'domain', params: { id: item.id } }"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.domain }}
                  </router-link>
                  <span
                    class="svg-icon svg-icon-md svg-icon-danger"
                    data-toggle="tooltip"
                    v-b-tooltip.hover.top="'Has wrong selector'"
                    v-if="item.has_wrong_selector"
                  >
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/General/Sad.svg"
                    />
                  </span>
                  <div v-if="item.settings && item.settings.tags">
                    <span
                      v-for="tag in item.settings.tags"
                      :key="`${item.id}${tag}`"
                      class="label label-lg label-light-primary label-inline font-weight-bold mr-1 mb-1 tag-item"
                      >{{ tag }}</span
                    >
                  </div>
                  <div class="offer" v-if="item.offer">
                    <OfferName :offer="item.offer" />
                  </div>
                </td>
                <td class="pl-0">
                  {{ item.total_visits }}
                </td>
                <td class="pl-0">
                  {{ item.today_visits }}
                </td>
                <td class="pl-0">
                  {{ renderDate(item.created_at) }}
                </td>
                <td class="pl-0">
                  {{ renderDate(item.last_visit_at) }}
                </td>
                <td class="pl-0">
                  {{ item.first_ip }}
                </td>
                <td class="pl-0">
                  {{ item.domain_type === "l" ? "Lander" : "Offer" }}
                </td>
                <td class="pl-0">
                  {{ item.country_code }}
                </td>
                <td class="pl-0">
                  <span
                    v-if="item.settings && item.settings.enabled"
                    class="svg-icon svg-icon-md svg-icon-success"
                  >
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Plus.svg"
                    />
                  </span>
                  <span v-else class="svg-icon svg-icon-md svg-icon-danger">
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Minus.svg"
                    />
                  </span>
                </td>
                <td class="text-right pr-0">
                  <div class="d-flex">
                    <button
                      class="btn btn-icon btn-light btn-sm"
                      @click="onOpenEdit(item)"
                      v-b-tooltip.hover.left="'Settings'"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg
                          :transformSource="transform"
                          src="public-media/svg/icons/General/Settings-1.svg"
                        />
                      </span>
                    </button>
                    <button
                      class="btn btn-icon btn-light btn-sm ml-1"
                      @click="onOpenConfirm(item)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <span
                          class="svg-icon svg-icon-md svg-icon-danger"
                          data-toggle="tooltip"
                          v-b-tooltip.hover.left="'Add to Blacklist'"
                          v-if="item.is_whitelisted"
                        >
                          <inline-svg
                            :transformSource="transform"
                            src="public-media/svg/icons/Navigation/Close.svg"
                          />
                        </span>

                        <span
                          class="svg-icon svg-icon-md svg-icon-success"
                          v-b-tooltip.hover.left="'Add to Whitelist'"
                          v-else
                        >
                          <inline-svg
                            :transformSource="transform"
                            src="public-media/svg/icons/Navigation/Check.svg"
                          />
                        </span>
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <pagination :count="count" />

    <domain-settings
      :show="show"
      :onHide="onHide"
      :onSubmit="onSubmit"
      :isLoading="false"
      :url="domainName"
      :defaultSettings="domainSettings"
      :isMultiple="isMultiple"
    />

    <ConfirmModal
      :show="showConfirm"
      :text="`Add <b>${domainName}</b> to ${listName}?`"
      :onConfirm="onConfirm"
      :onCancel="onCancel"
      title=""
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import * as TYPES from "@/core/services/store/modules/domains/types";
import * as PAGE_TYPES from "@/core/services/store/modules/page/types";

import { renderDate } from "@/core/services/services";
import DomainSettings from "./DomainSettings";
import ConfirmModal from "./ConfirmModal";
import Pagination from "@/view/content/Pagination";
import OfferName from "./OfferName.vue";
export default {
  name: "DomainsTable",
  props: { list: Array, count: Number, status: Object, title: String },
  components: { DomainSettings, ConfirmModal, Pagination, OfferName },
  data: () => ({
    show: false,
    editDomain: null,
    showConfirm: false,
    sorted_field: "",
    sorted_fields: {},
    selectedDomains: [],
    isMultiple: false,
  }),
  computed: {
    ...mapState({
      filter: (state) => state.page.filter,
      ordering: (state) =>
        state.page.filter ? state.page.filter.ordering : "",
    }),
    domainSettings: function () {
      return this.editDomain
        ? this.editDomain.settings
          ? this.editDomain.settings
          : null
        : null;
    },
    domainName: function () {
      return this.editDomain ? this.editDomain.domain : "";
    },
    listName: function () {
      return this.editDomain
        ? this.editDomain.is_whitelisted
          ? "Blacklist"
          : "Whitelist"
        : "";
    },
    renderDate: () => renderDate,
  },
  methods: {
    ...mapActions({
      loadDataAction: `domains/${TYPES.GET_DOMAINS}`,
      onEditDomain: `domains/${TYPES.EDIT_DOMAIN}`,
      onBulkEditDomains: `domains/${TYPES.BULK_EDIT_DOMAINS}`,
    }),
    ...mapMutations({
      changeFilter: `page/${PAGE_TYPES.CHANGE_FILTER}`,
    }),
    onHide: function () {
      this.show = false;
      this.isMultiple && (this.selectedDomains = []);
      this.isMultiple = false;
      this.editDomain = null;
    },
    onSubmit: function (data) {
      this.show = false;
      if (this.isMultiple) {
        let domains_settings = this.selectedDomains.map((item) => ({
          id: item,
          has_wrong_selector: false,
          ...data,
        }));
        this.onBulkEditDomains(domains_settings);
        this.isMultiple = false;
        this.selectedDomains = [];
      } else {
        let params = {
          settings: data.settings,
          has_wrong_selector: false,
        };
        this.onEditDomain({
          id: this.editDomain.id,
          data: params,
        });
      }
      this.editDomain = null;
    },
    onOpenMultipleEdit: function () {
      this.editDomain = {};
      this.show = true;
      this.isMultiple = true;
    },
    onOpenEdit: function (domain) {
      this.editDomain = domain;
      this.show = true;
    },
    onOpenConfirm: function (domain) {
      this.editDomain = domain;
      this.showConfirm = true;
    },
    onConfirm: function () {
      this.showConfirm = false;
      this.onEditDomain({
        id: this.editDomain.id,
        data: { is_whitelisted: !this.editDomain.is_whitelisted },
      });
      this.editDomain = null;
    },
    onCancel: function () {
      this.showConfirm = false;
      this.editDomain = null;
    },

    onFilter() {
      let ordering_string = "";
      for (let key in this.sorted_fields) {
        if (this.sorted_fields.hasOwnProperty(key)) {
          ordering_string = `${ordering_string}${ordering_string ? "," : ""}${
            this.sorted_fields[key].value === "asc" ? "" : "-"
          }${key}`;
        }
      }
      this.changeFilter({ ordering: ordering_string });
    },
    onSorted: function (field) {
      if (
        !this.sorted_fields[field] ||
        this.sorted_fields[field].value === "desc"
      ) {
        this.sorted_fields[field] = { value: "asc" };
      } else {
        this.sorted_fields[field] = { value: "desc" };
      }
      this.onFilter();
    },
    clearSorted: function (field) {
      if (this.sorted_fields[field]) {
        delete this.sorted_fields[field];
        this.onFilter();
      }
    },
    buildSortedCellClass: function (field) {
      let cell_name = field.substring(
        0,
        field.indexOf("_id") > 0 ? field.indexOf("_id") : field.length
      );
      return `kt-datatable__cell ${"kt-datatable__cell--sort"} ${cell_name} ${
        this.sorted_fields[field] ? " kt-datatable__cell--sorted" : ""
      }`;
    },
    buildSortedIconClass: function (field) {
      return this.sorted_fields[field] &&
        this.sorted_fields[field].value === "asc"
        ? "Arrow-down"
        : "Arrow-up";
    },
    setOrdering: function (ordering) {
      this.sorted_fields = {};
      let ordering_array = ordering.split(",");
      let index = 1;
      ordering_array.forEach((item) => {
        if (item[0] === "-") {
          this.sorted_fields[item.substring(1)] = {
            value: "desc",
            ordering: index,
          };
        } else {
          this.sorted_fields[item] = {
            value: "asc",
            ordering: index,
          };
        }
        index++;
      });
    },
    transform: function (svg) {
      let title = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "title"
      );
      title.textContent = "";

      const oldTitle = svg.getElementsByTagName("title")[0];
      if (oldTitle) {
        svg.replaceChild(title, oldTitle);
      }

      return svg;
    },
  },
  watch: {
    ordering(newValue) {
      this.setOrdering(newValue);
    },
  },
  created: function () {
    if (this.ordering) {
      this.setOrdering(this.ordering);
    }
  },
};
</script>

<style lang="scss">
.table-borderless thead {
  height: 24px;
}
.has-wrong-selector {
  background: #f9d9d9;
}

.domains-table-filters {
  .bulk-settings-btn {
    width: 140px;
    margin-right: 4px;
  }
}

.tag-item {
  padding: 2px 6px !important;
  font-size: 9px !important;
}

@media (max-width: 553px) {
  .domains-table-filters {
    width: 100%;
    flex-direction: column;
    .bulk-settings-btn {
      margin: 0;
      margin-bottom: 4px;
      width: 100%;
    }
  }
}
</style>
