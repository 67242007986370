<template>
  <div class="offer">
    <span v-if="isTruncated">{{ truncatedOffer }}</span>
    <span v-else>{{ offer }}</span>
    <button v-if="offer.length > 40" @click="toggleShowFull">
      {{ showFull ? "less" : "more" }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["offer"],
  data() {
    return {
      showFull: false,
    };
  },
  computed: {
    isTruncated() {
      return this.offer.length > 40 && !this.showFull;
    },
    truncatedOffer() {
      return this.offer.slice(0, 40) + "...";
    },
  },
  methods: {
    toggleShowFull() {
      this.showFull = !this.showFull;
    },
  },
};
</script>

<style lang="scss">
.offer {
  font-size: 11px;
  min-width: 320px;
  max-width: 320px;
}
button {
  margin-left: 5px;
  font-size: 12px;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
</style>
