<template>
  <b-modal
    ref="confirm_modal"
    size="xs"
    :title="`Please confirm ${title}`"
    @ok="onConfirm"
    @hidden="onCancel"
    @cancel="onCancel"
    ok-variant="success"
  >
    <template slot="modal-ok"> Confirm </template>
    <div v-html="text" />
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    onConfirm: Function,
    onCancel: Function,
    text: String,
    title: String,
    show: Boolean,
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.$refs["confirm_modal"].show();
      } else {
        this.$refs["confirm_modal"].hide();
      }
    },
  },
};
</script>

<style scoped></style>
